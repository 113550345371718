import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Ucapan } from '../components/ucapan'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import { Gift } from '../components/gift'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/aulia.aac'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/leo/covid.svg'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#C0573B'
let black = 'rgb(38,38,38)'

let id = 'leo-nabila'
let inisial_co = 'Leo'
let inisial_ce = 'Nabila'
let lengkap_co = 'Leo pernando'
let lengkap_ce = 'Nabila Secioria Ciptaning Gusti'
let bapak_co = `Bpk. H. M. Sa'i. S`
let ibu_co = 'Ibu HJ. Jumidah'
let bapak_ce = "Bpk. Ir. Slamet Riyadi "
let ibu_ce = "Ibu Nawang Setyaningsih s. Sos"
let ig_co = "Leopernd"
let ig_ce = "Nabilascio"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/18/2021"

let modal = gambar(pw(id,"modal.jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
let openlogo = pw(id,"logo.png")

let gmaps = "https://maps.app.goo.gl/5oVUUkd14EBfF8i6A"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NDVucWxwanY4OWE4OXJubW91cXA2Nm9qMTcgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = [
    "DSCF1281.jpg",
"DSCF1332.jpg",
"DSCF1333.jpg",
"DSCF1349.jpg",
"DSCF1380.jpg",
"DSCF1664.jpg",
"DSCF1717.jpg",
"HDP_5093.jpg",
"HDP_5444.jpg",
"HDP_5451.jpg",
]



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            showgift:false
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi ,showgift} = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title='Undanganku - Nabila & Leo'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undang.in/leo-nabila`}
                />

                <div id='gold5' style={{
                   backgroundColor:'#171717'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <br />
                            <br />
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>


                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                            <Container fluid className="py-3 px-0 col-md-8" data-aos="zoom-in" data-aos-duration="1000">
                                        <img src={gambar(pw(id, slide[5]), 95)} className="img-fluid w-100" />
                                    </Container>
                                <Container className="dinny px-3 pt-5 " style={{color:'white'}} data-aos="zoom-in" data-aos-duration="1000">
                                    <Item>

                                        <p className="fs16 text-center px-3">
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(Ar- Rum 21)

                                     </p>
                                    </Item>
                                    
                                </Container>
                                <Container fluid className="py-3 px-0 col-md-8" data-aos="zoom-out" data-aos-duration="1000">
                                    <img src={gambar(pw(id, slide[0]), 95)} className="img-fluid w-100" />
                                </Container>
                                <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'white' }}>
                                            Ngunduh Mantu of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>

                                <Container id='sectiongold55' className="py-3 dinny col-md-8" 
                                style={{ backgroundColor: 'rgba(255,255,255,0)', borderRadius: '15px' }}>
                                    <div id="leo" >
                                        <div className="d-flex align-content-center flex-wrap" data-aos="fade-left" data-aos-duration="1000">
                                            <Col className="px-1 p-md-4 my-auto" xs={4}>
                                                <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                            </Col>
                                            <Col xs={8}>
                                                <div className=" w-100 text-center">
                                                    <h1 className="w-100 text-center" style={{ color: cmain, fontFamily: "'Marck Script', cursive", fontSize:"72px"}}>
                                                        {inisial_ce}
                                                    </h1>
                                                    <h1 className="w-100 text-center" 
                                                    style={{ color: cmain, 
                                                    fontFamily: 'Tinos, serif', lineHeight: 1.25,fontSize:'1.4rem' }}>
                                                        {lengkap_ce}
                                                    </h1>

                                                    <p className='w-100 text-center' style={{ color: 'white' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                    <Item>

                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />
                                                    </Item>
                                                </div>

                                            </Col>
                                        </div>
                                        <div className="d-flex align-content-center flex-wrap" data-aos="fade-right" data-aos-duration="1000">
                                            <Col className="py-3 px-1 p-md-4 order-2 my-auto" xs={4}>
                                                <img src={gambar(foto_co, 90)} className='img-fluid w-100 ' />

                                            </Col>
                                            <Col className="order-1" xs={8}>

                                                <div className="w-100 text-center">
                                                <h1 className="w-100 text-center" style={{ color: cmain, fontFamily: "'Marck Script', cursive", fontSize:"72px"}}>
                                                        {inisial_co}
                                                    </h1>
                                                    <h1 className="w-100 text-center" style={{
                                                        color: cmain,
                                                        fontFamily: 'Tinos, serif', lineHeight: 1.25,fontSize:'1.4rem'
                                                    }}>
                                                        {lengkap_co}<br />
                                                    </h1>
                                                    <p className='w-100 text-center' style={{ color: 'white' }}>
                                                        <b>Putra dari :</b><br />
                                                        {bapak_co}  <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>

                                                    <Item>
                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                                    </Item>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>

                                </Container>
                                <Container fluid className="text-center px-4 dinny py-3" style={{ color: 'white' }} >
                                    <Item>
                                        <p className="fs16" data-aos="fade-up" data-aos-duration="1000">
                                            Yang akan dilaksanakan pada:
                                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20" data-aos="fade-up" data-aos-duration="1000">
                                            <b>
                                                SENIN <span className="fs36">18</span> JAN 2021
                                        </b>
                                        </p>
                                    </Item>

                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: 'white' }} data-aos="fade-up" data-aos-duration="1000">
                                            <b>Ngnduh Mantu</b><br />
                                            <span className="fs16">

                                                09:00 WIB - Selesai
                                            </span>


                                        </p>
                                       
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3" data-aos="fade-up" data-aos-duration="1000">
                                        Suban, Kec. Batang Asam, Kabupaten Tanjung Jabung Barat, Jambi 36552
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.1406159777885!2d102.9167603147537!3d-1.0560569992296585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMcKwMDMnMjEuOCJTIDEwMsKwNTUnMDguMiJF!5e0!3m2!1sid!2sid!4v1610201222030!5m2!1sid!2sid" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                                </div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Container id='sectiongold57'>
                                    <div className='pt-3'>

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>


                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            “Love recognizes no barriers. It jumps hurdles, leaps fences, penetrates walls to arrive at its destination full of hope.”
                                                        <br />– Maya Angelou
                                                        </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Container className="py-3" >
                                        <Item>
                                            <Col
                                                onClick={() => this.setState({ showgift: !showgift })}
                                                xs={10} md={4}
                                                style={{
                                                    border: `2px solid white`,
                                                    borderRadius: '10px'
                                                }}
                                                className="p-2 mx-md-2 mt-3 mt-md-0">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                        <b>Send Gift</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                        </Item>
                                </Container>

                               {
                                   showgift?( <Gift
                                    reza
                                    rinda
                                    content={
                                        [
                                            {
                                                bank: 'Bank Mandiri',
                                                norek: '1370016918498 ',
                                                nama: 'Nabila Secioria Ciptaning gusti'
                                            },
                                            {
                                                bank: 'Bank BNI',
                                                norek: '0805693658 ',
                                                nama: 'Leo pernando'
                                            }
                                        ]
                                    }
                                    caption='For those who want to give gifts to our wedding, kindly transfer to the following accounts :'
                                    ccaption="white"
                                    color={'white'}
                                    bg={cmain}
                                    langsung
                                   alamat="Jalan Lapangan Tembak No. 20 RT 02 RW 01 Desa Pekuncen, Kab. Kebumen - Sempor, Jawa Tengah"
                                />
                                ):false
                               }


                               
                                <Foot ig={logoig} />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

